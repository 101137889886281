// @ts-nocheck
import {
  BigBlueButton,
  ContactUsLink,
  DashboardLink,
  FaqLink,
  LanguagePicker,
  LogInLink,
  LogOutLink,
  LogoLink,
  SettingsLink,
  StyledNavLink,
  mobileOnly,
  SkipToContentLink,
} from './headerLinks';
import React, { useEffect, useRef, useState } from 'react';
import { isEnrolled } from '../../utils';
import {
  EXTERNAL_AUTH_PROVIDER_ALLOWLIST,
  STUDY_STATUSES_ENROLLMENT_CLOSED,
} from '../../constants';
import ContactModal from '../contactModal';
import { useMediaQuery } from 'react-responsive';

import _ from 'lodash';
import { connect } from 'react-redux';
import styled, { css } from 'styled-components';
import { withRouter } from 'react-router-dom';
import {
  LandingSidePadding,
  LandingPageWidth,
} from '../../routes/education/components';
import { Spacer } from '..';
import Stickyfill from 'stickyfilljs';
import { simpleUserLogout } from '../../utils/simpleUserLogout';
import { useTranslated } from '../../hooks';
import { withTranslation } from 'react-i18next';
import TextSizeSelect from './textSize';
import { actions } from '../../redux';
import { Flex, Button } from '@evidation/ui';
import { Link } from 'react-router-dom';
import * as Auth from 'aws-amplify/auth';
import { useUserSession } from '../../hooks/useUserSession';

const mobileMaxWidth = `800px`;
const desktopMinWidth = `801px`;

const HeaderButton = styled(BigBlueButton)`
  height: 30px;
  padding: 1px 20px;
  text-transform: uppercase;
  border: 0 !important;
  font-size: ${14 / 16}rem;
`;

export const DesktopHeaderButton = styled(BigBlueButton)`
  height: 41px;
  padding: 7px 35px;
  text-transform: uppercase;
  border: 0 !important;
  font-size: 1rem;
`;

const logoLink = (layout = ``) => {
  switch (layout) {
    case `education`:
    case `follow_up`:
    case `terminal_state`:
      return `/`;
    case `dashboard`:
    case `screener`:
    case `registration`:
    case `informed_consent`:
    default:
      return `/${layout}`;
  }
};

const StickyHeader = styled.header`
  flex: 0 0 auto;
  position: ${(props) => (props.sticky ? 'sticky' : 'relative')};
  top: 0;
  width: 100%;
  z-index: 5;
  background: #fff;
  border-bottom: 1px solid #e3e3e3;

  @media (max-width: ${mobileMaxWidth}) {
    max-height: 100%;
  }
`;

const HeaderContent = styled.div`
  max-width: ${LandingPageWidth};
  margin: auto;
  height: 100%;
  display: flex;
`;

const DesktopLinks = styled.div`
  display: flex;
  align-items: baseline;
  flex: 1;
  justify-content: space-between;
  & > * {
    padding: 0 10px;
  }
  & > :last-child {
    padding: 0;
  }
`;

const MobileHeaderEligibilityMaxWidth = '340px';
const MobileHeaderEligibility = styled.div`
  ${mobileOnly};

  @media (max-width: ${MobileHeaderEligibilityMaxWidth}) {
    display: none !important;
  }
`;

const MobileLinks = styled.div`
  position: absolute;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 10px 28px;
  padding-top: 10px;
  border-bottom: 1px solid #eff2f4;

  & > * {
    align-self: auto;
    padding: 5px;
  }
  & > :first-child {
    padding-top: 0;
  }
`;

const NavContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 15px 0;
  @media (max-width: ${mobileMaxWidth}) {
    padding: 10px 0;
  }

  & > * {
    display: flex;
  }
  ${(p) =>
    p.noCheckEligibility &&
    css`
      @media (max-width: ${mobileMaxWidth}) {
        display: flex;
        padding: 11px 0;
      }
    `}
`;

const TitleSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;

  @media (min-width: 500px) and (max-width: ${mobileMaxWidth}) {
    ${(p) => p.isEducation && `padding-left: 54px;`}
  }

  @media (max-width: ${mobileMaxWidth}) {
    ${(p) => p.hasStudyTitle && 'padding-top: 10px;'}
  }

  @media (max-width: ${mobileMaxWidth}) {
    ${(p) => p.hasStudyTitle && 'padding-top: 10px;'}
  }

  @media (max-width: ${MobileHeaderEligibilityMaxWidth}) {
    margin-right: auto;
    margin-left: auto;
    padding-right: 30px;
  }

  @media (max-width: ${mobileMaxWidth}) {
    & > #t-AchievementLogo {
      padding-inline: 0;
    }
  }

  @media (min-width: ${desktopMinWidth}) {
    & > * {
      padding-left: 0 !important;
      margin-right: auto;
    }

    & > #t-AchievementLogo {
      padding-bottom: 5px !important;
      padding-inline: 15px;
    }
  }

  ${(p) =>
    p.centered &&
    css`
      @media (max-width: ${mobileMaxWidth}) {
        display: flex;
        flex: 1 auto;
        justify-content: center;
        margin-right: 30px;
      }
    `};
`;

const StudyTitle = styled.div`
  color: #2a3f53;
  font-family: Heebo;
  font-size: 1rem;
  line-height: 24px;
  padding: 0 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${(p) => (p.enrollmentIsClosed ? '70vw' : '50vw')};
  align-self: center;
`;

const ParticipantName = styled.div`
  @media (max-width: ${mobileMaxWidth}) {
    border-bottom: 1px solid #e3e3e3;
    width: 100%;
  }
`;

const Name = styled.p`
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  color: #000000;
  text-transform: capitalize;
  margin-right: 8px;

  @media (max-width: ${mobileMaxWidth}) {
    font-size: 1.25rem;
    margin-block: 4px;
  }
`;

export const Header: React.FC<any> = ({
  enrollmentLogOut,
  status,
  study_status,
  layout,
  participant_layout,
  portalEmail,
  hasAcmCookie,
  meta,
  t,
  enrollment_identifier,
  participant_auth_token,
  destroyAuthToken,
  hero_button,
  location,
  first_name,
  ...rest
}) => {
  const [showMobileDropdown, setShowMobileDropdown] = useState(false);
  const stickyHeaderRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 800 });

  const { study_name, brand, authentication = {} } = meta;
  const buttonText = t('components.header.eligibilityButton');
  hero_button = hero_button || buttonText;
  const startButtonText = t('components.header.startButton');
  const mobileMenuButtonText = t('components.header.mobileMenuButton');
  const mobileCloseMenuButtonText = t('components.header.closeMenuButton');
  const mobileMenuText = showMobileDropdown
    ? mobileCloseMenuButtonText
    : mobileMenuButtonText;

  const enrolled = isEnrolled(status, participant_layout);

  const isLanding = location.pathname === `/`;
  const isEducation =
    layout === `education` && participant_layout !== 'dashboard' && isLanding;
  const enrollmentIsClosed = Object.values(
    STUDY_STATUSES_ENROLLMENT_CLOSED,
  ).includes(study_status);

  const authProvider = _.has(authentication, 'provider')
    ? _.includes(
        EXTERNAL_AUTH_PROVIDER_ALLOWLIST,
        authentication.provider.toLowerCase(),
      )
    : false;

  // IF we have a language inside the secondary_locales array, then we must give the user the
  //  ability to change languages.
  const shouldDisplayLanguagePicker =
    meta.i18n_enabled && meta.secondary_locales?.length > 0;
  const isOpenEnrollmentLandingPage = isEducation && !enrollmentIsClosed;
  const shouldDisplayEligibility = isOpenEnrollmentLandingPage;

  const toggleMobileNavOff = () => setShowMobileDropdown(false);
  const toggleMobileNav = () => setShowMobileDropdown((state) => !state);

  const LeftLinks = () => {
    const content = (
      <React.Fragment>
        <DashboardLink onClick={toggleMobileNavOff} />
        <SettingsLink onClick={toggleMobileNavOff} />
      </React.Fragment>
    );
    return enrolled && isMobile ? (
      content
    ) : enrolled ? (
      <Flex>{content}</Flex>
    ) : null;
  };

  // Used to determine if a third-party url is needed to complete
  // user logout process.
  const logout = async () => {
    try {
      await Auth.signOut({ global: true });
    } catch (err) {
    } finally {
      simpleUserLogout(meta.slug);
      await destroyAuthToken(
        enrollment_identifier,
        participant_auth_token,
        participant_auth_token,
      );
      enrollmentLogOut({ redirect: meta?.authentication?.details?.logout });
    }
  };

  const RightLinks = ({ setLoginType, participantName }) => {
    const session = useUserSession();
    const home = useTranslated('components.header.links.home');
    return (
      <>
        {participantName && (
          <ParticipantName className="participant-name">
            <Name>{participantName}</Name>
          </ParticipantName>
        )}
        <TextSizeSelect />
        {isEducation && (
          <StyledNavLink
            to="/"
            onClick={() => {
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth',
              });
              toggleMobileNavOff();
            }}
            isActive={(match, location) => match && location.hash === ``}
            className="navbar--item"
          >
            {home}
          </StyledNavLink>
        )}
        {isEducation && <FaqLink onClick={toggleMobileNavOff} />}
        <ContactModal onClose={toggleMobileNavOff}>
          {({ showModal }) => (
            <ContactUsLink
              onClick={showModal}
              padding={showMobileDropdown ? '0' : '15px'}
            />
          )}
        </ContactModal>
        {!session && <LogInLink onClick={toggleMobileNavOff} />}
        {session && <LogOutLink onClick={() => logout(setLoginType)} />}
      </>
    );
  };

  useEffect(() => {
    if (stickyHeaderRef.current) {
      Stickyfill.add(stickyHeaderRef.current);
    }
  }, []);

  useEffect(() => {
    const runModifyHeader = async () => {
      await window.customHead.loaded();
      await window.customBody.loaded();
      if (window.modifyHeader && typeof window.modifyHeader === 'function') {
        window.modifyHeader();
      }
    };

    runModifyHeader().catch(console.error);
  }, [
    enrollmentLogOut,
    status,
    study_status,
    layout,
    participant_layout,
    portalEmail,
    hasAcmCookie,
    meta,
    t,
    enrollment_identifier,
    participant_auth_token,
    destroyAuthToken,
    hero_button,
    location,
    first_name,
    rest,
  ]);

  return (
    <StickyHeader sticky={isEducation} ref={stickyHeaderRef}>
      <Spacer
        pl={LandingSidePadding}
        pr={LandingSidePadding}
        style={{ height: '100%' }}
      >
        <HeaderContent>
          <NavContainer noCheckEligibility={!isOpenEnrollmentLandingPage}>
            <SkipToContentLink />
            <TitleSection
              centered={!isOpenEnrollmentLandingPage}
              hasStudyTitle={study_name}
              isEducation={isEducation}
            >
              <LogoLink
                to={logoLink(participant_layout)}
                onClick={toggleMobileNavOff}
                id="t-AchievementLogo"
                testId="t-AchievementLogo"
                ariaLabel={brand?.branding_json?.logo_instructions}
              />
              <StudyTitle
                id="t-studyTitle"
                enrollmentIsClosed={enrollmentIsClosed}
              >
                {/* TODO: discuss if this will be controllable for all future studies */}
                {brand?.brand_name !== 'PPMI' && study_name}
              </StudyTitle>
            </TitleSection>

            {isMobile && (
              <MobileHeaderEligibility>
                {shouldDisplayLanguagePicker && (
                  <LanguagePicker
                    locale={meta.locale}
                    primaryLocale={meta.primary_locale}
                    secondaryLocales={meta.secondary_locales}
                    participantLocale={participantLocale}
                  />
                )}
                {shouldDisplayEligibility && isMobile ? (
                  <HeaderButton as={Link} to="/log-in">
                    {startButtonText}
                  </HeaderButton>
                ) : (
                  // When the button isn't displayed we still need some spacing to keep the logo centered
                  <div style={{ width: 50 }} />
                )}
              </MobileHeaderEligibility>
            )}

            {isMobile && (
              <Button
                aria-expanded={showMobileDropdown}
                onClick={toggleMobileNav}
                className="ehp-mobile-menu--toggle"
              >
                {mobileMenuText}
              </Button>
            )}

            {!isMobile && (
              <DesktopLinks>
                <LeftLinks />
                <Flex
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                  items="center"
                >
                  <RightLinks
                    participantName={first_name}
                    setLoginType={authProvider}
                  />
                  <>
                    {shouldDisplayLanguagePicker && (
                      <LanguagePicker
                        locale={meta.locale}
                        primaryLocale={meta.primary_locale}
                        secondaryLocales={meta.secondary_locales}
                        participantLocale={participantLocale}
                      />
                    )}
                    {shouldDisplayEligibility && (
                      <DesktopHeaderButton as={Link} to="/sign-up">
                        {hero_button}
                      </DesktopHeaderButton>
                    )}
                  </>
                </Flex>
              </DesktopLinks>
            )}
          </NavContainer>
        </HeaderContent>
      </Spacer>
      {isMobile && showMobileDropdown && (
        <MobileLinks>
          <LeftLinks />
          <RightLinks participantName={first_name} />
        </MobileLinks>
      )}
    </StickyHeader>
  );
};

export default withRouter(
  connect(
    ({
      participant: {
        portalEmail,
        hasAcmCookie,
        layout: participant_layout,
        enrollment_identifier,
        participant_auth_token,
        locale,
        first_name,
      },
      landing: { layout, nodes },
    }) => {
      const hero_props = {};
      if (!_.isEmpty(nodes)) {
        Object.assign(
          hero_props,
          _.pick(nodes[0].content, [
            'hero_title',
            'hero_content',
            'hero_button',
          ]),
        );
      }
      return {
        portalEmail,
        hasAcmCookie,
        layout,
        participant_layout,
        enrollment_identifier,
        participant_auth_token,
        participantLocale: locale,
        first_name,
        ...hero_props,
      };
    },
    (dispatch) => ({
      destroyAuthToken: (
        enrollment_identifier,
        participant_auth_token,
        device_auth_token,
      ) =>
        dispatch(
          actions.destroyAuthToken(
            enrollment_identifier,
            participant_auth_token,
            device_auth_token,
          ),
        ),
    }),
  )(withTranslation()(Header)),
);
